const useColumns = [
  {
    text: "Pedido",
    align: "start",
    value: "code",
    class: "header-title",
    sortable: false,
  },
  {
    text: "Cédula",
    align: "start",
    value: "client.dni",
    class: "header-title",
    sortable: false,
  },
  {
    text: "Cliente",
    align: "start",
    value: "nameClient",
    class: "header-title",
    sortable: false,
  },
  {
    text: "Fecha",
    align: "start",
    value: "createdAt",
    class: "header-title",
    sortable: false,
  },
  {
    text: "Total",
    align: "start",
    value: "total",
    class: "header-title",
    sortable: false,
  },
  {
    text: "Cuotas",
    align: "start",
    value: "weeks",
    class: "header-title",
    sortable: false,
  },
  {
    text: "Semana transcurrida",
    align: "center",
    value: "payment",
    class: "header-title",
    sortable: false,
  },
  {
    text: "Vendedor",
    align: "start",
    value: "nameSeller",
    class: "header-title",
    sortable: false,
  },
  {
    text: "Acción",
    value: "actions",
    sortable: false,
    class: "header-title",
  },
];

export default useColumns;
