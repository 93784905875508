<template>
  <div class="order-info-admin">
    <v-card flat min-height="498" max-height="498">
      <v-card-text class="content">
        <div class="detail-grid">
          <div class="client">
            <h2 class="title-c">Datos del cliente</h2>
            <div class="info-grid">
              <label>Cédula:</label>
              <div>{{ item.client.dni | unitMile }}</div>
            </div>
            <div class="info-grid">
              <label>Nombre:</label>
              <div>{{ item.client.name }} {{ item.client.lastName }}</div>
            </div>
            <div class="info-grid">
              <label>Teléfono:</label>
              <div>{{ item.client.phone }}</div>
            </div>
            <div class="info-grid">
              <label>Email:</label>
              <div>{{ item.client.email || "No asignado" }}</div>
            </div>
            <div class="info-grid">
              <label>Dirección:</label>
              <div>{{ item.client.address }}</div>
            </div>
          </div>
          <div class="seller">
            <h2 class="title-c">Datos de la orden</h2>
            <div class="info-grid">
              <label>Pedido:</label>
              <div>{{ item.code || "No asignado" }}</div>
            </div>
            <div class="info-grid">
              <label>Fecha:</label>
              <div>{{ item.createdAt | dateTime }}</div>
            </div>
            <div class="info-grid">
              <label>Estado:</label>
              <div>{{ item.check | status }}</div>
            </div>

            <h2 class="title-c">Datos del vendedor</h2>
            <div class="info-grid">
              <label>Cédula:</label>
              <div>{{ item.seller.dni | unitMile }}</div>
            </div>
            <div class="info-grid">
              <label>Nombre:</label>
              <div>{{ item.seller.name }} {{ item.seller.lastName }}</div>
            </div>
            <div class="info-grid">
              <label>Teléfono:</label>
              <div>{{ item.seller.phone || "No asignado" }}</div>
            </div>
          </div>
        </div>

        <h2 class="title-c" style="margin-bottom: 15px">Detalles de compra</h2>
        <v-divider></v-divider>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Código</th>
                <th class="text-left">Producto</th>
                <th class="text-center">Cantidad</th>
                <th class="text-center">Precio unitario</th>
                <th class="text-right">Total</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(product, i) in item.products" :key="i">
                <td class="text-left">{{ product.code }}</td>
                <td class="text-left">{{ product.name }}</td>
                <td class="text-center">{{ product.count }}</td>
                <td class="text-center">{{ product.price }} $</td>
                <td class="text-right">{{ product | priceTotal }} $</td>
              </tr>
              <tr class="last-row">
                <td class="text-right" colspan="3"></td>
                <td class="text-right"><b>TOTAL</b></td>
                <td class="text-right">
                  <div style="margin-top: 5px">
                    <b>{{ item.total | formatDolar }} $</b>
                  </div>
                  <div>
                    <b>{{ item.totalBs | formatBs }} Bs</b>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "OrderInfo",
  data: () => ({}),
  props: {
    item: {},
  },
  created() {
    setTimeout(() => {
      const scroll = document.getElementById("detailOrder");
      scroll.scrollTop = 0;
    });
  },
};
</script>

<style lang="scss">
.order-info-admin {
  .content {
    padding: 16px 0px !important;
  }

  .text-left,
  .text-center,
  .text-right {
    font-size: 14px !important;
  }

  b {
    color: var(--color-second);
    font-weight: 600;
  }

  table th + th {
    border-left: 1px solid #dddddd;
  }
  table td + td {
    border-left: 1px solid #dddddd;
  }

  table th:first-child,
  table td:first-child {
    border-left: 1px solid #dddddd;
  }

  table th:last-child,
  table td:last-child {
    border-right: 1px solid #dddddd;
  }

  table .last-row td:first-child {
    border-left: none;
  }

  table .last-row td:nth-child(2),
  table .last-row td:nth-child(3) {
    border-bottom: 1px solid #dddddd;
  }

  .detail-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;

    .title-c {
      font-size: 20px;
      font-weight: 600;
      color: var(--color-second);
      margin-top: 15px;
      margin-bottom: 15px;
    }

    .info-grid {
      display: grid;
      grid-template-columns: 100px 1fr;
      align-items: center;
      column-gap: 5px;
      margin-bottom: 6px;
      font-size: 14px;
      color: var(--color-second);

      label {
        font-weight: 500;
      }
    }
  }
}
</style>
