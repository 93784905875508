<template>
  <div class="orden page-list">
    <v-card class="orden-tab" v-if="!tabComplete">
      <v-tabs
        centered
        icons-and-text
        grow
        color="second"
        v-model="tab"
        @change="typeTab($event)"
      >
        <v-tabs-slider></v-tabs-slider>

        <v-tab>
          Cuotas
          <v-icon>mdi-cash-multiple</v-icon>
        </v-tab>

        <v-tab>
          Nuevo
          <v-icon>mdi-plus</v-icon>
        </v-tab>

        <v-tab>
          Verificar
          <v-icon>mdi-check</v-icon>
        </v-tab>

        <v-tab>
          Completado
          <v-icon>mdi-check-all</v-icon>
        </v-tab>
      </v-tabs>
    </v-card>

    <v-card>
      <v-card-title>
        <h1>{{ !tabComplete ? title : "Histórico de Compras" }}</h1>
      </v-card-title>
      <v-data-table
        :mobile-breakpoint="768"
        :headers="tableHeaders"
        :items="listData"
        :search="search"
        :loading="loaderTable"
        :item-class="row_classes"
        :footer-props="{
          itemsPerPageOptions: [10, 25, 50, 100, -1],
        }"
        loading-text="Cargando... Por favor espere."
      >
        <template v-slot:[`item.code`]="{ item }">
          {{ item.code || "No asignado" }}
        </template>

        <template v-slot:[`item.client.dni`]="{ item }">
          {{ item.client.dni | unitMile }}
        </template>

        <!-- <template v-slot:[`item.client`]="{ item }">
          {{ item.client.name }} {{ item.client.lastName }}
        </template> -->

        <template v-slot:[`item.createdAt`]="{ item }">
          {{ item.createdAt | date }}
        </template>

        <template v-slot:[`item.total`]="{ item }">
          {{ item.total }} $
        </template>

        <!-- <template v-slot:[`item.seller`]="{ item }">
          {{ item.seller.name }} {{ item.seller.lastName }}
        </template> -->

        <template v-slot:[`item.weeks`]="{ item }">
          <div class="total-weeks">
            <div>Semanas:</div>
            <div>{{ totalCuotasWeeks(item) }}</div>
          </div>
          <div class="total-weeks">
            <div>Total:</div>
            <div>{{ totalCuotasPayment(item) }}</div>
          </div>
        </template>

        <template v-slot:[`item.payment`]="{ item }">
          {{ pastWeek(item) }} Semana
        </template>

        <template v-slot:[`item.actions`]="{ item, index }">
          <CustomButtonAction
            :value="item"
            :btnDetail="true"
            :btnCheck="tab !== 3 && tab !== 0"
            :btnDelete="tab !== 3 && tab !== 0"
            :index="index"
            :loaderCheck="loaderCheck"
            :loaderDelete="loaderDelete"
            :textCheck="textCheck"
            @onChangeDetail="detailItem($event)"
            @onChangeDelete="deleteItem($event, index)"
            @onChangeCheck="checkItem($event, index)"
          />
        </template>

        <template v-slot:top>
          <v-toolbar flat>
            <v-row>
              <v-col cols="11" md="6">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Buscar ..."
                  single-line
                  hide-details
                  outlined
                  dense
                  clearable
                  color="second"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-spacer></v-spacer>
          </v-toolbar>
          <v-divider></v-divider>
        </template>
      </v-data-table>
    </v-card>

    <OrderDetail :dialog.sync="detailModal" :item="this.selectItem" />

    <CustomDialog
      :dialog.sync="deleteModal"
      :title="'Confirmar eliminación'"
      :message="'¿Estás seguro que quieres eliminar el registro?'"
      :maxWidth="'320'"
      @onChange="btnDelete()"
    ></CustomDialog>
  </div>
</template>

<script>
import * as moment from "moment";
import useColumns from "./useColumns";
import useColumnsCuota from "./useColumnsCuota";
import OrderDetail from "./detail.vue";
import CustomButtonAction from "../../../../shared/components/custom-button-action/CustomButtonAction.vue";
import CustomDialog from "../../../../shared/components/custom-dialog/CustomDialog.vue";
import OrderServices from "../../../../core/services/order-services";
import OrderAdminStorage from "../../../../core/storage/order-admin-storage";

export default {
  name: "Order",
  data: () => ({
    title: "Nuevos pedidos",
    textCheck: "Verificar",
    search: "",
    selectItem: {},
    listData: [],
    loaderTable: false,
    loaderDelete: {},
    loaderCheck: {},
    selectIndex: null,
    tableHeaders: [],
    tableHeadersOrder: useColumns,
    tableHeadersCuota: useColumnsCuota,
    detailModal: false,
    tab: 1,
    deleteModal: false,
    selectOrder: null,
  }),
  props: {
    tabComplete: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CustomButtonAction,
    OrderDetail,
    CustomDialog,
  },
  watch: {
    "$route.query": {
      immediate: true,
      handler(newVal) {
        this.selectOrder = newVal.order;
        switch (parseInt(newVal.check)) {
          case 1:
            this.tab = 0;
            this.getListOrderStorage(1);
            this.getListOrden(1);
            break;
          case 2:
            this.tab = 1;
            this.getListOrderStorage(2);
            this.getListOrden(2);
            break;
          case 3:
            this.tab = 2;
            this.getListOrderStorage(3);
            this.getListOrden(3);
            break;
          case 4:
            this.tab = 3;
            this.getListOrderStorage(4);
            this.getListOrden(4);
            break;
        }
      },
    },
  },
  created() {
    this.tableHeaders = this.tableHeadersOrder;
    if (this.tabComplete) {
      this.tab = 2;
      this.getListOrderStorage(4);
      this.getListOrden(4);
    } else if (!this.selectOrder) {
      this.getListOrderStorage();
      this.getListOrden(2);
    }
  },
  methods: {
    detailItem(item) {
      this.detailModal = true;
      this.selectItem = item;
      this.selectOrder = null;
    },
    deleteItem(item, index) {
      this.deleteModal = true;
      this.selectItem = item;
      this.selectIndex = index;
      this.selectOrder = null;
    },
    checkItem(item, index) {
      const { updateCheck } = OrderServices();
      const {
        orderNewAdd,
        orderCheckAdd,
        orderCompleteAdd,
        orderNewDeleteById,
        orderCheckDeleteById,
      } = OrderAdminStorage();
      let check = 1;

      let nameClient = `${item.client.name} ${item.client.lastName}`;
      nameClient = nameClient.toUpperCase().split(" ");
      let codeName = null;
      const code = [];

      this.loaderCheck = { [index]: true };
      console.log("aqui", this.tab);

      switch (this.tab) {
        case 1:
          check = 3;
          break;
        case 2:
          check = 4;

          for (let i = 0; i < nameClient.length; i++) {
            if (i <= 1) {
              code.push(nameClient[i].charAt(0));
            }
          }

          codeName = code.join("") + moment().format("DDMMYYYYHHmm");
          break;
      }

      updateCheck(item.id, { check, code: codeName }).then((resp) => {
        const response = resp.data;
        if (response.code === 201) {
          switch (response.data.check) {
            case 2:
              orderNewAdd(response.data);
              break;
            case 3:
              orderNewDeleteById(item.id);
              orderCheckAdd(response.data);
              this.$toast.success("La orden paso a verificar");
              this.typeTab(2);
              this.tab = 2;
              break;
            case 4:
              orderCheckDeleteById(item.id);
              orderCompleteAdd(response.data);
              this.$toast.success("La orden paso a completado");
              this.typeTab(3);
              this.tab = 3;
              break;
          }

          this.loaderCheck = { [index]: false };
          this.selectOrder = null;
          this.getListOrderStorage(response.data.check);
        }
      });
    },
    async getListOrderStorage(check = 2) {
      let list = [];
      const { orderNewAll, orderCheckAll, orderCompleteAll } =
        OrderAdminStorage();
      if (!this.tabComplete) {
        this.listData = [];
      }

      switch (check) {
        case 1:
          list = await orderNewAll();
          this.listData = list.filter((item) => item.check === 1);
          break;
        case 2:
          list = await orderNewAll();
          this.listData = list.filter((item) => item.check === 2);
          break;
        case 3:
          this.listData = await orderCheckAll();
          break;
        case 4:
          this.listData = await orderCompleteAll();
          break;
      }
    },
    getListOrden(check = 2) {
      const { getOrderAll } = OrderServices();
      const { orderNewAdd, orderCheckAdd, orderCompleteAdd } =
        OrderAdminStorage();
      this.loaderTable = true;
      getOrderAll(check).then((resp) => {
        const response = resp.data;
        if (response.code === 200) {
          switch (check) {
            case 1:
              response.data.map((item) => {
                item.nameClient = `${item.client.name} ${item.client.lastName}`;
                item.nameSeller = `${item.seller.name} ${item.seller.lastName}`;
                orderNewAdd(item);
              });
              this.textCheck = "Verificar";
              break;
            case 2:
              response.data.map((item) => {
                item.nameClient = `${item.client.name} ${item.client.lastName}`;
                item.nameSeller = `${item.seller.name} ${item.seller.lastName}`;
                orderNewAdd(item);
              });
              this.textCheck = "Verificar";
              break;
            case 3:
              response.data.map((item) => {
                item.nameClient = `${item.client.name} ${item.client.lastName}`;
                item.nameSeller = `${item.seller.name} ${item.seller.lastName}`;
                orderCheckAdd(item);
              });
              this.textCheck = "Completado";
              break;
            case 4:
              response.data.map((item) => {
                item.nameClient = `${item.client.name} ${item.client.lastName}`;
                item.nameSeller = `${item.seller.name} ${item.seller.lastName}`;
                orderCompleteAdd(item);
              });
              break;
          }
        }
        this.loaderTable = false;
        this.getListOrderStorage(check);
      });
    },
    typeTab(value) {
      this.tab = value;
      switch (value) {
        case 0:
          this.getListOrden(1);
          this.title = "Cuotas";
          this.tableHeaders = this.tableHeadersCuota;
          break;
        case 1:
          this.getListOrden(2);
          this.title = "Nuevos pedidos";
          this.tableHeaders = this.tableHeadersOrder;
          break;
        case 2:
          this.getListOrden(3);
          this.title = "Verificar pedidos";
          this.tableHeaders = this.tableHeadersOrder;
          break;
        case 3:
          this.getListOrden(4);
          this.title = "Pedidos completados";
          this.tableHeaders = this.tableHeadersOrder;
          break;
      }
    },
    btnDelete() {
      const { deleteOrder } = OrderServices();
      const { orderNewDeleteById, orderCheckDeleteById } = OrderAdminStorage();
      this.loaderDelete = { [this.selectIndex]: true };

      deleteOrder(this.selectItem.id).then((resp) => {
        const response = resp.data;
        if (response.code === 200) {
          switch (this.selectItem.check) {
            case 1:
              orderNewDeleteById(this.selectItem.id);
              this.getListOrderStorage(1);
              break;
            case 2:
              orderNewDeleteById(this.selectItem.id);
              this.getListOrderStorage(2);
              break;
            case 3:
              orderCheckDeleteById(this.selectItem.id);
              this.getListOrderStorage(3);
              break;
          }

          this.$toast.success("El registro ha sido eliminado exitosamente");
          this.loaderDelete = { [this.selectIndex]: false };
        }
      });
    },
    row_classes(item) {
      if (item.id === this.selectOrder) {
        return "row-active";
      }
    },
    totalCuotasWeeks(item) {
      let count = 0;
      item.weeks.map((element) => {
        if (element.payment > 0) {
          count = count + 1;
        }
      });
      return `${count} / ${item.weeks.length}`;
    },
    totalCuotasPayment(item) {
      let count = 0;
      item.weeks.map((element) => {
        if (element.payment > 0) {
          count = count + parseFloat(element.payment);
        }
      });
      const format = new Intl.NumberFormat("en-EN", {
        minimumFractionDigits: 2,
      }).format(count);
      return `${format} $`;
    },
    pastWeek(item) {
      const today = moment();
      const created = moment(item.createdAt);
      const week = today.diff(created, "week");
      return `${week}`;
    },
  },
};
</script>

<style lang="scss">
.orden {
  .orden-tab {
    margin-bottom: 15px;
  }

  .total-weeks {
    display: grid;
    grid-template-columns: 80px 1fr;
  }
}
</style>
