<template>
  <v-dialog
    v-model="viewModal"
    max-width="900px"
    persistent
    scrollable
    content-class="admin-dialog detail-order-admin"
  >
    <v-card min-height="600" max-height="600">
      <v-card-title class="card-title">
        <span class="headline">Detalles</span>

        <v-spacer></v-spacer>

        <CustomButtonAction
          :btnCancel="true"
          :btnDownPdf="true"
          colorBtnCancel="white"
          colorBtnDownPdf="white"
          :loaderDownPdf="loaderDownPdf"
          @onChangeCancel="cancel()"
          @onChangeDownPdf="btnDownPdf()"
        />
      </v-card-title>

      <v-divider></v-divider>

      <v-tabs centered grow color="second" v-model="tab">
        <v-tabs-slider></v-tabs-slider>

        <v-tab> Orden </v-tab>

        <v-tab> Comentarios </v-tab>

        <v-tab> Adjuntos </v-tab>
      </v-tabs>

      <v-divider></v-divider>

      <v-card-text id="detailOrder">
        <div v-if="tab === 0">
          <OrderInfo :item="item" />
        </div>
        <div v-if="tab === 1">
          <OrderComment :item="item" />
        </div>
        <div v-if="tab === 2">
          <OrderAdjunt :item="item" />
        </div>
      </v-card-text>
    </v-card>
    <CustomPrintOrder :order="item" />
  </v-dialog>
</template>

<script>
import { jsPDF } from "jspdf";
import OrderComment from "./comment.vue";
import OrderAdjunt from "./adjunto.vue";
import OrderInfo from "./info.vue";
import CustomButtonAction from "../../../../shared/components/custom-button-action/CustomButtonAction.vue";
import CustomPrintOrder from "../../../../shared/components/custom-print-order/CustomPrintOrder.vue";

export default {
  name: "OrderDetail",
  data: () => ({
    viewModal: false,
    tab: 0,
    loaderDownPdf: false,
  }),
  components: {
    CustomButtonAction,
    OrderComment,
    OrderAdjunt,
    OrderInfo,
    CustomPrintOrder,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    item: {},
  },
  watch: {
    dialog: function (value) {
      this.viewModal = value;
    },
  },
  methods: {
    cancel() {
      this.viewModal = false;
      this.tab = 0;
      this.$emit("update:dialog", false);
    },
    btnDownPdf() {
      this.loaderDownPdf = true;
      const div = document.getElementById("order-pdf");

      const doc = new jsPDF("p", "mm", "a4");
      doc.html(div, {
        callback: (print) => {
          print.save(`Pedido-${this.item.code}.pdf`);
          this.loaderDownPdf = false;
        },
        html2canvas: {
          scale: 0.235,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.detail-order-admin {
  .v-card > .v-card__text {
    padding: 0 24px 0px;
  }

  .v-card > .v-card__text::-webkit-scrollbar {
    width: 8px;
  }

  .v-card > .v-card__text::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  .v-card > .v-card__text::-webkit-scrollbar-thumb {
    background: #666666;
    border-radius: 10px;
  }

  .v-card > .v-card__text::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }
}
</style>
