<template>
  <div class="order-adjunto-admin">
    <v-card flat min-height="498" max-height="498">
      <v-card-text class="content">
        <div v-for="(item, i) in item.attached" :key="i">
          <img :src="item | imageDefault" @click="openImg(item)" />
        </div>
      </v-card-text>
    </v-card>

    <CustomViewerImage :dialog.sync="dialog" :src.sync="image" />
  </div>
</template>

<script>
import CustomViewerImage from "../../../../shared/components/custom-viewer-image/custom-viewer-image.vue";

export default {
  name: "OrderAdjunt",
  data: () => ({
    dialog: false,
    image: "",
  }),
  props: {
    item: {},
  },
  components: {
    CustomViewerImage,
  },
  created() {
    setTimeout(() => {
      const scroll = document.getElementById("detailOrder");
      scroll.scrollTop = 0;
    });
  },
  methods: {
    openImg(item) {
      this.dialog = true;
      this.image = item;
    },
  },
};
</script>

<style lang="scss">
.order-adjunto-admin {
  .content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 20px;
    row-gap: 2px;
    padding: 10px 0 !important;

    img {
      border-radius: 5px;
      display: flex;
      align-items: flex-start;
      margin: 10px 0px;
      border: solid 1px #ccc;
      cursor: pointer;
    }
  }
}
</style>
