import { db } from "./db.js";

const OrderAdminStorage = () => {
  const orderNewAdd = async (data) => {
    await db.orderNew.put(data);
  };

  const orderNewAll = async () => {
    return await db.orderNew.orderBy("createdAt").reverse().toArray();
  };

  const orderNewDeleteById = async (id) => {
    await db.orderNew.delete(id);
  };

  const orderNewClear = async () => {
    await db.orderNew.clear();
  };

  const orderCheckAdd = async (data) => {
    await db.orderCheck.put(data);
  };

  const orderCheckAll = async () => {
    return await db.orderCheck.orderBy("createdAt").reverse().toArray();
  };

  const orderCheckDeleteById = async (id) => {
    await db.orderCheck.delete(id);
  };

  const orderCheckClear = async () => {
    await db.orderCheck.clear();
  };

  const orderCompleteAdd = async (data) => {
    await db.orderComplete.put(data);
  };

  const orderCompleteAll = async () => {
    return await db.orderComplete.orderBy("createdAt").reverse().toArray();
  };

  const orderCompleteClear = async () => {
    await db.orderComplete.clear();
  };

  return {
    orderNewAdd,
    orderNewAll,
    orderNewDeleteById,
    orderNewClear,
    orderCheckAdd,
    orderCheckAll,
    orderCheckDeleteById,
    orderCheckClear,
    orderCompleteAdd,
    orderCompleteAll,
    orderCompleteClear,
  };
};

export default OrderAdminStorage;
