<template>
  <div class="backdrop-pdf">
    <div id="order-pdf" class="content-pdf">
      <div class="">
        <table
          class="header-bottom-pdf"
          align="center"
          border="0"
          width="800"
          cellspacing="0"
        >
          <tr class="header-pdf">
            <td class="column1-pdf">
              <img src="img/home/logo.png" alt="logo" />
            </td>
            <td class="column2-pdf">
              <div class="center-pdf">
                <div class="barra-pdf"></div>
                <div class="title-pdf">Gestion de Ventas JL</div>
              </div>
            </td>
          </tr>
        </table>

        <div class="bar-pdf"></div>

        <table
          align="center-pdf"
          border="0"
          width="800"
          cellspacing="0"
          style="margin-top: 20px"
        >
          <tr valign="top">
            <td>
              <table class="table-factura-pdf" style="width: 100%">
                <tr>
                  <td colspan="2">
                    <div class="title-pdf">Datos del Cliente</div>
                  </td>
                </tr>
                <tr>
                  <td style="width: 90px">Cédula:</td>
                  <td>{{ order.client.dni | unitMile }}</td>
                </tr>
                <tr>
                  <td>Nombre:</td>
                  <td>{{ order.client.name }} {{ order.client.lastName }}</td>
                </tr>
                <tr>
                  <td>Teléfono:</td>
                  <td>{{ order.client.phone }}</td>
                </tr>
                <tr>
                  <td>Email:</td>
                  <td>{{ order.client.email || "No asigando" }}</td>
                </tr>
                <tr valign="top">
                  <td>Dirección:</td>
                  <td style="padding-right: 20px">
                    {{ order.client.address }}
                  </td>
                </tr>
              </table>
            </td>
            <td>
              <table
                class="table-factura-pdf"
                style="width: 320px; padding-left: 60px"
              >
                <tr>
                  <td colspan="2">
                    <div class="title-pdf">Datos de la orden</div>
                  </td>
                </tr>
                <tr>
                  <td style="width: 90px">Pedido:</td>
                  <td>{{ order.code || "No asigando" }}</td>
                </tr>
                <tr>
                  <td>Fecha:</td>
                  <td>{{ order.createdAt | dateTime }}</td>
                </tr>
              </table>

              <table
                class="table-factura-pdf"
                style="width: 320px; padding-left: 60px"
              >
                <tr>
                  <td colspan="2">
                    <div class="title-pdf" style="margin-top: 20px">
                      Datos del Vendedor
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style="width: 90px">Cédula:</td>
                  <td>{{ order.seller.dni | unitMile }}</td>
                </tr>
                <tr>
                  <td>Nombre:</td>
                  <td>{{ order.seller.name }} {{ order.seller.lastName }}</td>
                </tr>
                <tr>
                  <td>Teléfono:</td>
                  <td>{{ order.seller.phone || "No asigando" }}</td>
                </tr>
              </table>
            </td>
          </tr>
        </table>

        <table
          class="table-product-title-pdf"
          align="center"
          border="0"
          width="800"
          cellspacing="0"
          style="margin-top: 20px"
        >
          <tr>
            <td colspan="2">
              <div class="title-pdf">Detalles de compra</div>
            </td>
          </tr>
        </table>

        <table
          class="table-product-pdf"
          align="center"
          border="0"
          width="800"
          cellspacing="0"
        >
          <tr>
            <td style="padding-left: 10px">
              <b>Producto</b>
            </td>
            <td style="text-align: center; width: 120px">
              <b>Cantidad</b>
            </td>
            <td style="text-align: center; width: 120px">
              <b>Precio Unitario</b>
            </td>
            <td style="text-align: right; width: 120px; padding-right: 10px">
              <b>Total</b>
            </td>
          </tr>
          <tr v-for="(item, i) in order.products" :key="'A' + i">
            <td v-if="i <= 30" style="padding-left: 10px">
              {{ item.name }} {{ i }}
            </td>
            <td v-if="i <= 30" style="text-align: center; width: 120px">
              {{ item.count }}
            </td>
            <td v-if="i <= 30" style="text-align: center; width: 120px">
              {{ item.price }}
            </td>
            <td
              v-if="i <= 30"
              style="text-align: right; width: 120px; padding-right: 10px"
            >
              {{ (item.count * item.price) | formatDolar }}
            </td>
          </tr>
          <div v-if="order.products.length > 30" class="tr-white-pdf">
            <tr v-for="(list, i) in numbers" :key="'B' + i">
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </div>
          <tr v-for="(list, i) in order.products" :key="'C' + i">
            <td v-if="i > 30" style="padding-left: 10px">
              {{ list.name }} {{ i }}
            </td>
            <td v-if="i > 30" style="text-align: center; width: 120px">
              {{ list.count }}
            </td>
            <td v-if="i > 30" style="text-align: center; width: 120px">
              {{ list.price }}
            </td>
            <td
              v-if="i > 30"
              style="text-align: right; width: 120px; padding-right: 10px"
            >
              {{ (list.count * list.price) | formatDolar }}
            </td>
          </tr>
          <tr>
            <td colspan="2" style="border: none"></td>
            <td style="text-align: right; padding-right: 10px">
              <b>TOTAL</b>
            </td>
            <td style="text-align: right; padding-right: 10px">
              <div>
                <b>{{ order.total | formatDolar }} $</b>
              </div>
              <div style="margin-top: 5px">
                <b>{{ order.totalBs | formatBs }} Bs</b>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomPrintOrder",
  data: () => ({}),
  props: {
    order: {
      type: Object,
    },
  },
};
</script>

<style lang="scss">
.backdrop-pdf {
  position: fixed;
  z-index: 1;
  top: -3000px;
}

.content-pdf {
  // overflow: scroll;
  // height: 500px;
  width: 895px;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 43px;

  .header-bottom-pdf {
    border-bottom: solid 1.5px #dbdbdb;
    padding-bottom: 8px;
  }

  .header-pdf .column1-pdf {
    width: 90px;
    text-align: center;
  }

  .header-pdf .column1-pdf img {
    width: 60px;
  }

  .column2-pdf {
    display: flex;
    height: 60px;
    width: 350px;
  }

  .column2-pdf .center-pdf {
    display: flex;
    align-items: center;
    margin-top: 15px;
  }

  .column2-pdf .title-pdf {
    font-size: 22px;
    font-weight: 700;
    color: #222;
    width: 350px;
    text-align: left;
    font-family: "Roboto", sans-serif !important;
  }

  .column2-pdf .barra-pdf {
    border-left: solid 2px #e2e2e2;
    height: 40px;
    padding-left: 20px;
  }

  .table-factura-pdf {
    tr {
      text-align: left;
      color: #222;
      td {
        padding: 2px 0px;
        font-size: 14px;
        font-family: "Roboto", sans-serif !important;
      }
    }
  }

  .table-factura-pdf .title-pdf {
    font-size: 18px;
    font-weight: 700;
    color: #222;
    font-family: "Roboto", sans-serif !important;
  }

  .table-product-title-pdf .title-pdf {
    font-size: 18px;
    font-weight: 700;
    color: #222;
    margin-bottom: 10px;
    text-align: left;
    font-family: "Roboto", sans-serif !important;
  }

  .table-product-pdf {
    border-collapse: collapse;
  }

  .table-product-pdf {
    tr {
      text-align: left;
      color: #222;

      td {
        border: solid 1px #b5b5b5;
        padding: 4px 10px;
        font-size: 14px;
        font-family: "Roboto", sans-serif !important;

        b {
          font-family: "Roboto", sans-serif !important;
        }

        div {
          font-family: "Roboto", sans-serif !important;
        }
      }
    }
  }

  .tr-white {
    tr {
      td {
        border: none;
      }
    }
  }
}
</style>
