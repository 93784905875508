var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"orden page-list"},[(!_vm.tabComplete)?_c('v-card',{staticClass:"orden-tab"},[_c('v-tabs',{attrs:{"centered":"","icons-and-text":"","grow":"","color":"second"},on:{"change":function($event){return _vm.typeTab($event)}},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider'),_c('v-tab',[_vm._v(" Cuotas "),_c('v-icon',[_vm._v("mdi-cash-multiple")])],1),_c('v-tab',[_vm._v(" Nuevo "),_c('v-icon',[_vm._v("mdi-plus")])],1),_c('v-tab',[_vm._v(" Verificar "),_c('v-icon',[_vm._v("mdi-check")])],1),_c('v-tab',[_vm._v(" Completado "),_c('v-icon',[_vm._v("mdi-check-all")])],1)],1)],1):_vm._e(),_c('v-card',[_c('v-card-title',[_c('h1',[_vm._v(_vm._s(!_vm.tabComplete ? _vm.title : "Histórico de Compras"))])]),_c('v-data-table',{attrs:{"mobile-breakpoint":768,"headers":_vm.tableHeaders,"items":_vm.listData,"search":_vm.search,"loading":_vm.loaderTable,"item-class":_vm.row_classes,"footer-props":{
        itemsPerPageOptions: [10, 25, 50, 100, -1],
      },"loading-text":"Cargando... Por favor espere."},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.code || "No asignado")+" ")]}},{key:"item.client.dni",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("unitMile")(item.client.dni))+" ")]}},{key:"item.createdAt",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.createdAt))+" ")]}},{key:"item.total",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.total)+" $ ")]}},{key:"item.weeks",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"total-weeks"},[_c('div',[_vm._v("Semanas:")]),_c('div',[_vm._v(_vm._s(_vm.totalCuotasWeeks(item)))])]),_c('div',{staticClass:"total-weeks"},[_c('div',[_vm._v("Total:")]),_c('div',[_vm._v(_vm._s(_vm.totalCuotasPayment(item)))])])]}},{key:"item.payment",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.pastWeek(item))+" Semana ")]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
      var index = ref.index;
return [_c('CustomButtonAction',{attrs:{"value":item,"btnDetail":true,"btnCheck":_vm.tab !== 3 && _vm.tab !== 0,"btnDelete":_vm.tab !== 3 && _vm.tab !== 0,"index":index,"loaderCheck":_vm.loaderCheck,"loaderDelete":_vm.loaderDelete,"textCheck":_vm.textCheck},on:{"onChangeDetail":function($event){return _vm.detailItem($event)},"onChangeDelete":function($event){return _vm.deleteItem($event, index)},"onChangeCheck":function($event){return _vm.checkItem($event, index)}}})]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"11","md":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar ...","single-line":"","hide-details":"","outlined":"","dense":"","clearable":"","color":"second"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-spacer')],1),_c('v-divider')]},proxy:true}],null,true)})],1),_c('OrderDetail',{attrs:{"dialog":_vm.detailModal,"item":this.selectItem},on:{"update:dialog":function($event){_vm.detailModal=$event}}}),_c('CustomDialog',{attrs:{"dialog":_vm.deleteModal,"title":'Confirmar eliminación',"message":'¿Estás seguro que quieres eliminar el registro?',"maxWidth":'320'},on:{"update:dialog":function($event){_vm.deleteModal=$event},"onChange":function($event){return _vm.btnDelete()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }