<template>
  <div class="order-comment-admin">
    <v-card flat min-height="490" max-height="490">
      <v-card-text class="content">
        <v-container>
          <v-row v-for="(item, i) in item.comment" :key="i">
            <v-col cols="12">
              <v-card>
                <v-card-subtitle class="subtitle">
                  {{ item.createdAt | dateTime }}
                </v-card-subtitle>
                <v-card-title class="title">
                  {{ item.user }}
                </v-card-title>
                <v-card-text class="comment-m">
                  {{ item.message }}
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>

        <div
          :class="
            item.comment.length > 3 ? 'comment-message' : 'comment-message2'
          "
        >
          <v-form ref="form" v-model="valid" lazy-validation>
            <div class="content-grid">
              <div class="message">
                <v-textarea
                  outlined
                  label="Agregar comentario"
                  v-model="comment"
                  color="second"
                  :rules="requiredRules"
                  rows="4"
                  dense
                ></v-textarea>
              </div>
              <div class="btn">
                <v-btn
                  color="second"
                  class="white--text"
                  @click="btnSend()"
                  :loading="btnLoader"
                >
                  Enviar
                </v-btn>
              </div>
            </div>
          </v-form>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import OrderServices from "../../../../core/services/order-services";
import OrderAdminStorage from "../../../../core/storage/order-admin-storage";
import useValidate from "../../../../shared/validate/useValidate";

export default {
  name: "OrderComment",
  data: () => ({
    comment: "",
    valid: false,
    btnLoader: false,
    requiredRules: [useValidate["required"]],
    scrollInvoked: 0,
  }),
  props: {
    item: {},
    typeTab: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapState(["user"]),
  },
  created() {
    if (this.item.comment.length > 3) {
      setTimeout(() => {
        const scroll = document.getElementById("detailOrder");
        const height = scroll.scrollHeight;
        scroll.scrollTop = height;
      });
    }
  },
  methods: {
    onScroll() {
      this.scrollInvoked++;
    },
    btnSend() {
      const { updateOrder } = OrderServices();
      const { orderNewAdd, orderCheckAdd, orderCompleteAdd } =
        OrderAdminStorage();

      const validate = this.$refs.form.validate();
      if (validate) {
        const comment = {
          _id: this.user._id,
          user: `${this.user.name} ${this.user.lastName}`,
          message: this.comment,
          createdAt: new Date(),
          typeUser: this.user.typeUser,
          new: true,
        };
        this.item.comment.push(comment);
        this.btnLoader = true;

        updateOrder(this.item.id, this.item).then((resp) => {
          const response = resp.data;
          if (response.code === 201) {
            switch (this.typeTab) {
              case 0:
                orderNewAdd(response.data);
                break;
              case 1:
                orderCheckAdd(response.data);
                break;
              case 2:
                orderCompleteAdd(response.data);
                break;
            }

            this.$toast.success(
              "El comentario se ha actualizado correctamente"
            );
          }
          this.btnLoader = false;
          this.$refs.form.reset();
          setTimeout(() => {
            const scroll = document.getElementById("detailOrder");
            const height = scroll.scrollHeight;
            scroll.scrollTop = height;
          });
        });
      }
    },
  },
};
</script>

<style lang="scss">
.order-comment-admin {
  position: relative;

  .v-card > .title {
    padding: 0px 16px !important;
    font-size: 14px !important;
    font-weight: 500;
    margin-top: -10px;
    color: var(--color-second);
  }

  .subtitle {
    padding: 10px 16px 1px;
    text-align: right;
    font-size: 12px !important;
    color: var(--color-second);
    font-weight: 300;
  }

  .container {
    padding: 10px 2px 10px;
  }

  .v-card > .comment-m {
    padding: 0px 16px 16px !important;
    font-size: 13px;
    color: var(--color-second);
    font-weight: 300;
  }

  .content {
    padding: 16px 16px 0px;
  }

  .comment-message {
    position: sticky;
    bottom: 0px;
    width: 100%;
    background: #fff;
    padding-top: 10px;
    border-top: solid 1px #e0e0e0;

    .content-grid {
      display: grid;
      grid-template-columns: 4fr 1fr;
      column-gap: 16px;

      .message {
        .v-input {
          font-size: 14px !important;
        }
      }
    }
  }

  .comment-message2 {
    position: absolute;
    bottom: 0px;
    width: 100%;
    background: #fff;
    padding-top: 10px;
    border-top: solid 1px #e0e0e0;

    .content-grid {
      display: grid;
      grid-template-columns: 4fr 1fr;
      column-gap: 16px;

      .message {
        .v-input {
          font-size: 14px !important;
        }
      }
    }
  }
}
</style>
